*{
  margin: 0;
  padding: 0;
  font-family:'Nunito Sans', sans-serif;;
}

/* LOGIN PAGE */

.login{
  border: none;
  border-radius: 25px;
  margin-top: 79px;
  box-shadow:  1px 1px 20px 11px #dbb82d;
}

.login img{
  height: 330px;
  width: 427px;
  padding: 23px;
}

.login label p{
  margin: 0;
}

.login img.business-option{
  height: 60px;
  width: 60px;
  padding: 0;
}

.login img.bar{
  border-radius: 50%;
  background-color: black;
}

.login label.active img {
  border: 2px solid #dbb82d;
  border-radius: 50%;
}

.login input[type="radio"] {
  accent-color: #dbb82d;
}

.btn-primary{
  background-color: #dbb82d;
  border: none;
  margin-bottom: 15px;
  font-size: 20px;
  cursor: pointer;
}

.btn-primary:hover{
  background-color: #ead864 !important;
}  

.btn:disabled{
  background-color: #ead864;
}


/* NAV BAR */

.style-nav-logo{
  height: 50px;
  width: 50px;
}

.nav-link:hover{
  color:black;
}

.navbar-nav .nav-link.active{
  color: #6c540e;
}

.style-nav{
  border: none;
  background-color: #dbb82d;
  padding: 5px 10px 5px 10px;
}

.logout{
  border: none;
  background: #dbb82d;
}

.style-nav-link{
  font-size: 15px;
  color: black;
  font-weight: bold;
}

.style-name{
  color:black;
  font-weight: bold;
  font-size: 12px;
  margin:0;
  text-transform: uppercase;

}

/* MODAL */
.style-modal-header{
 background-color: #dbb82d;
}

.text-modal{
  font-weight: bold;
}


/* INVENTORY */

.style-title-page{
  font-weight: bold;
  font-size: 30px;
}

.btn-table{
  background-color: #dbb82d;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: white;
}

.btn.btn-table:hover{
  background-color:#dbb82d;
  color: white;
}

table .row-click {
  cursor: pointer;
}

/* CASHIER */

.cashier-select {
  font-size: 15px;
}

.cashier-form .input-title {
  font-weight: bold;
}

.cashier-form .invalid-input {
  border-color: red;
  box-shadow: none;
}



/* GLOBAL */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.text-ellipsis{
  text-overflow: ellipsis;
  overflow: hidden;
}


/* SALES PAGE */

.style-icon-sale{
  color: #dbb82d;
  font-size: 30px;
  margin-right: 22px;
  margin-left: 6px;
}

.style-cards{
  display: flex;
  width: max-content;
  align-items: center;
  border-radius: 19px;
  box-shadow:  1px 1px 8px 2px #dbb82d;
  padding: 10px 9px;
}

.style-cards p {
  margin-bottom: 0;
}

.bold{
  font-weight: bold;
}

.react-datepicker-wrapper input{
  border-radius: 5px;
  padding: 3px 5px;
  border-color: #dbb82d;
  width: 100%;
}
.react-datepicker .react-datepicker__day--in-range{
  background-color: #dbb82d;
}
/* CLOSED CASHIER */

.cards-closed{
  width: max-content;
  align-items: center;
  border-radius: 19px;
  box-shadow:  1px 1px 8px 2px #dbb82d;
  padding: 10px 9px;
}

.cards-closed p{
  margin-bottom: 0;
}

.cards-closed p span{
  font-weight: normal;
}

.style-buttom-closed{
  font-size: 15px;
}

.button-closed{
  padding: 0px 37px;
}

.yellow-color{
  color:#dbb82d;
}

.modal-content .modal-user-name {
  box-shadow: 1px 1px 8px 5px white;
  border-radius: 20px;
  padding: 7px 12px;
  color: #6c540e;
}

.error-msg {
  color: red;
}

/* MOBILE STYLES */

@media (max-width: 700px){
  .login img{
    height: 300px;
    width: 330px;
  }

  .t-responsive-large {
    width: 660px;
  }

  .t-responsive-medium {
    width: 580px;
  }

  .cashier-form {
    font-size: 12px;
  }

  .cashier-form .btn-table {
    padding: 0 7px;
  }
  .cashier-form .btn-table i {
    font-size: 12px;
  }

  .cashier-form input {
    font-size: 12px;
  }
  .cashier-select {
    font-size: 12px;
  }
}